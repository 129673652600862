import { endOfMonth, startOfMonth } from '../../../utils/timeUtil';
import { TrpcBase } from '../../../utils/trpc';
import { Form, Input, Space } from 'antd';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import style from './RoomHistory.module.css';
import { useUserAgent } from '../../../utils/userAgent';
import { LineComponent } from '../flow/Line';

export default function RoomHistory() {
	const { formLayout } = useUserAgent();
	const [form] = Form.useForm();
	const roomNumber = Form.useWatch('roomNumber', form);
	const { data } = TrpcBase.apartment.list.useQuery(
		{
			roomNumber: roomNumber,
			startTime: endOfMonth(dayjs().year(2018)).valueOf(),
			endTime: startOfMonth(dayjs().add(2, 'month')).valueOf(),
			orderBy: 'checkOutTime',
		},
		{
			enabled: roomNumber !== undefined,
		},
	);

	const lineData = (data?.list ?? []).map((i) => {
		return {
			...i,
			checkTime: dayjs(i.checkInTime).format('YYYY-M'),
			rent: isNil(i.rent) ? 0 : i.rent,
		};
	});

	const config = {
		data: lineData,
		xField: 'checkTime',
		yField: 'rent',
		xAxis: {
			// type: 'timeCat',
			// tickCount: 5,
		},

		seriesField: 'remarks',
	};

	return (
		<div>
			<div className={style.control}>
				<Form
					layout={formLayout}
					form={form}
					onValuesChange={(v) => {}}
					onFinish={(values) => {}}
				>
					<Space>
						{/* <Form.Item label="时间" name="time">
            <DatePicker picker="month"  />
          </Form.Item> */}
						<Form.Item label="房间号" name="roomNumber">
							<Input />
						</Form.Item>
					</Space>
				</Form>
			</div>

			<LineComponent {...config} height={300} />
		</div>
	);
}
